<template>
  <div class="pa-6">
    <div class="font-weight-medium mb-2">New email address</div>
    <v-text-field
      type="email"
      outlined
      v-model="local.email"
      @blur="showErrors.email = true"
      @focus="showErrors.email = false"
      :error-messages="
        showErrors.email && errorMessages.email ? errorMessages.email : null
      "
    ></v-text-field>

    <div class="font-weight-medium mb-2">Enter password</div>
    <v-text-field
      type="password"
      outlined
      v-model="local.password"
      @blur="showErrors.password = true"
      @focus="showErrors.password = false"
      :error-messages="
        showErrors.password && errorMessages.password
          ? errorMessages.password
          : null
      "
    ></v-text-field>
    <div class="d-flex align-center">
      <v-btn
        text
        :disabled="processing"
        depressed
        @click="close"
        >{{ closeLabel }}</v-btn
      >
      <v-spacer />
      <v-btn
        v-bind="buttonType"
        depressed
        @click="submit"
        >Save</v-btn
      >
    </div>
  </div>
</template>

<script>
import { isEmpty, isEqual } from 'lodash'
export default {
  name: 'UserMenuChangeEmail',
  props: {
    closeLabel: {
      default: 'Close',
    },
  },
  data: function () {
    return {
      disable: false,
      local: {
        email: '',
        password: '',
      },
      processing: false,
      showErrors: {
        email: false,
        password: false,
      },
    }
  },
  computed: {
    errorMessages() {
      const msgs = {
        email: null,
        password: null,
      }

      if (this.localFormatted.email === '') {
        msgs.email = 'Required field'
      }

      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (!msgs.email && !pattern.test(this.localFormatted.email)) {
        msgs.email = 'Not a valid email address'
      }

      if (this.localFormatted.password === '') {
        msgs.password = 'Required field'
      }
      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(
        this.localFormatted.email,
        this.$store.getters['account/auth_user'].email
      )
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    localFormatted() {
      return {
        email: this.local.email.trim(),
        password: this.local.password,
      }
    },
  },
  methods: {
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showError[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    close() {
      this.$emit('close')
    },
    async submit() {
      this.processing = true
      let result = {}
      if (this.isValid) {
        if (this.isChanged) {
          result = await this.$store.dispatch(
            'account/auth_update_email',
            this.localFormatted
          )
        } else {
          result = {
            type: 'error',
            message: 'Cannot enter your existing email address',
          }
        }
      }
      this.showErrorsSwitch()
      this.processing = false
      this.$store.commit('app/SET_USER_MENU_ALERT', result)
      if (!isEmpty(result) && result.type === 'success') {
        this.close()
      }
    },
  },
}
</script>
